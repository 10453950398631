<template>
  <b-card>
    <b-row class="p-0 mb-1">
      <b-col cols="12">
        <b-button
          :variant="file.name ? 'outline-primary' : 'gradient-primary'"
          data-action-type="new"
          class="mr-1 btn-sm"
          @click="
            (v) => {
              openUploadModal();
            }
          "
        >
          <feather-icon icon="UploadIcon" class="mr-50" />
          {{ file.name ? $t("hasAttachMents") : $t("attachFile") }}
        </b-button>
        <a
          :href="selectedItem.attachmentUrl"
          @click.prevent="downloadItem(selectedItem)"
          v-if="id && selectedItem.attachmentUrl"
          class="mr-1 btn-sm btn btn-outline-primary"
        >
          <feather-icon icon="DownloadIcon" class="mr-50" />
          {{ $t("attachedFile") }}
        </a>
      </b-col>
    </b-row>
    <b-modal
      no-close-on-backdrop
      ref="upload-modal"
      cancel-variant="outline-secondary"
      centered
      size="lg"
      hide-footer
      :title="$t('attachFile')"
    >
      <g-form>
        <b-row>
          <b-col cols="12">
            <div>
              <input
                type="file"
                class="excel-upload-input"
                @change="handleFileUpload($event)"
                accept=".pdf, .jpg, .jpeg, .bmp, .png, .doc, .docx, .xlsx, .PNG, .zip"
              />
              <b-alert
                v-if="file"
                show
                fade
                class="mt-2 text-center"
                variant="success"
              >
                <div class="alert-body">
                  <span>{{ $t("importSuccsefly") }} {{ file.name }}</span>
                </div>
              </b-alert>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <hr />
          </b-col>
          <b-col cols="12" class="d-flex justify-content-end">
            <b-button class="mx-1" variant="primary" @click="closeUploadModal">
              {{ $t("save") }}
            </b-button>
            <b-button
              class="mx-1"
              @click="removeAttachment()"
              variant="danger"
              data-action-type="delete"
              :disabled="!file"
            >
              {{ $t("cancel") }}
            </b-button>
          </b-col>
        </b-row>
      </g-form>
    </b-modal>
    <b-modal
      ref="search-modal"
      cancel-variant="outline-secondary"
      centered
      size="lg"
      hide-footer
      :title="selectedItem.id > 0 ? $t('accounts') : $t('accounts')"
    >
      <b-row>
        <b-col cols="12" md="4" class="pb-1">
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block"
              :clearable="true"
              :placeholder="$t('search')"
            />
          </div>
        </b-col>
        <b-col cols="12" class="medium-window">
          <b-table
            ref="accountsTable"
            :items="subAccounts"
            :fields="tableColumns"
            :filter="searchQuery"
            :filter-included-fields="filterOn"
            :tbody-tr-class="
              (item) => {
                if (item && item.isDistributable === true)
                  return 'bg-light-primary';
              }
            "
          >
            <template #head(actions)>
              <span></span>
            </template>
            <!-- Column: Actions -->
            <template #cell(actions)="{ item }">
              <b-button
                data-action-type="save"
                size="sm"
                variant="outline-primary"
                @click="
                  (val) => {
                    addItemToItemsTable(item);
                  }
                "
              >
                <feather-icon icon="PlusIcon" />
              </b-button>
            </template>
          </b-table>
        </b-col>
        <b-col cols="12" class="d-flex justify-content-end py-1 pb-2">
          <b-button class="mx-1" variant="secondary" @click="closeModal">
            {{ $t("Close") }}
          </b-button>
        </b-col>
      </b-row>
    </b-modal>

    <g-form @submit="save">
      <b-row>
        <b-col md="4">
          <!-- code  -->
          <g-field
            id="code"
            type="number"
            :value.sync="selectedItem.code"
            label-text="code"
            name="code"
            disabled
          />
        </b-col>

        <!-- voucher date  -->
        <b-col
          v-if="!currentBranch.setDefaultDate"
          md="4"
        >
          <g-field
            :value="getDate(selectedItem.voucherDate)"
            label-text="date"
            disabled
            name="voucherDate"
          />
        </b-col>

        <b-col
          v-else
          md="4"
        >
          <g-picker
            :value.sync="selectedItem.voucherDate"
            label-text="date"
            name="voucherDate"
          />
        </b-col>

        <b-col md="4">
          <g-field
            :value.sync="selectedItem.transactionTime"
            label-text="transactionTime"
            name="transactionTime"
            readonly
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col md="4">
          <g-field
            id="reference"
            :value.sync="selectedItem.sourceTransactionCode"
            label-text="reference"
            name="reference"
            disabled
          />
          <!-- <label class="col-form-label pt-0">{{$t('reference')}}</label>
        <b-form-input :disabled="!!id" v-model="selectedItem.sourceTransactionCode" type="number"></b-form-input> -->
        </b-col>
        <b-col md="4">
          <g-field
            id="sourceTransactionType"
            :value.sync="sourceTransactionType"
            label-text="transactionType"
            name="sourceTransactionType"
            disabled
          />
        </b-col>
        <b-col
          v-if="checkIsOpening && this.$can('createOpeningVoucher')"
          md="4"
        >
          <b-form-group :label="$t('openingVoucher')">
            <b-form-checkbox
              v-model="selectedItem.isOpening"
              class="custom-control-primary"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="pt-1">
        <!-- notes -->
        <b-col md="12">
          <b-form-group :label="$t('notes')">
            <b-form-textarea
              id="textarea"
              v-model="selectedItem.description"
              label-text="notes"
              rows="2"
              max-rows="6"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="justify-content-between">
        <b-col md="8">
          <div class="d-flex align-items-center justify-content-start">
            <vue-autosuggest
              :suggestions="filteredOptions"
              :input-props="inputProps"
              v-model="query"
              @selected="onSelected"
              @click="clickHandler"
              :limit="10"
              @input="onInputChange"
            >
              <template slot-scope="{ suggestion }">
                <span class="my-suggestion-item">{{
                  suggestion.item.name
                }}</span>
              </template>
            </vue-autosuggest>
          </div>
        </b-col>
        <b-col
          md="4"
          class="add-account d-flex align-lines-center justify-content-end mb-1 mb-md-0"
        >
          <b-button
            variant="gradient-primary"
            @click="
              (v) => {
                $refs['search-modal'].show();
              }
            "
          >
            <feather-icon icon="PlusSquareIcon" class="mr-50" />
            {{ $t("addAccount") }}
          </b-button>
        </b-col>
      </b-row>
      <br />
      <b-row>
        <!-- second table -->
        <b-table
          primary-key="id"
          show-empty
          striped
          foot-clone
          hover
          fixed
          small
          ref="lines-table"
          :items="selectedItem.lines"
          :fields="secondTableColumns"
          :is-busy="isTableBusy"
          :noAction="true"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
          :empty-text="$t('noMatchingRecordsFound')"
          :tbody-tr-class="
            (item) => {
              if (item && item.isDistributable === true)
                return 'bg-light-primary';
            }
          "
        >
          <template #cell(accountArabicName)="{ item }">
            <g-field
              class="mb-0"
              :value.sync="item.accountId"
              :dir="isRight ? 'rtl' : 'ltr'"
              field="select"
              name="subAccounts"
              :clearable="false"
              :options="subAccounts"
              label="arabicName"
              @change="
                (v) => {
                  item.accountCode = v.code;
                  item.isDistributable = v.isDistributable;
                }
              "
            />
          </template>
          <template #cell(debit)="{ item }">
            <g-field
              class="mb-0"
              :value.sync="item.debit"
              rules="required|min_value:0"
              :shortDesc="true"
              :disabled="disableValueDebit(item, selectedItem)"
              type="number"
              @input="
                (v) => {
                  getSummary();
                  if (!item.credit) {
                    item.credit = 0;
                  }
                }
              "
            />
          </template>
          <template #cell(credit)="{ item }">
            <g-field
              class="mb-0"
              :value.sync="item.credit"
              rules="required|min_value:0"
              :shortDesc="true"
              :disabled="disableValueCredit(item, selectedItem)"
              type="number"
              @input="
                (v) => {
                  getSummary();
                  if (!item.debit) {
                    item.debit = 0;
                  }
                }
              "
            />
          </template>
          <template #cell(proofNumber)="{ item }">
            <g-field
              class="mb-0"
              :value.sync="item.proofNumber"
              :shortDesc="true"
              type="text"
            />
          </template>
          <template #cell(description)="{ item }">
            <g-field
              class="mb-0"
              :value.sync="item.description"
              :shortDesc="true"
              type="text"
            />
          </template>
          <template #head(actions)>
            <span></span>
          </template>
          <!-- Column: Actions -->
          <template #cell(actions)="{ item }">
            <div class="text-nowrap">
              <span
                v-if="
                  item.isDistributable === true &&
                  (item.debit !== 0 || item.credit !== 0)
                "
              >
                <feather-icon
                  :id="`invoice-row-${item.id}-SlackIcon`"
                  icon="SlackIcon"
                  class="mx-1 clickable"
                  @click="addDetailCostCenter(item)"
                />
                <b-tooltip
                  :title="$t('shareToCostcenters')"
                  placement="top"
                  :target="`invoice-row-${item.id}-SlackIcon`"
                />
              </span>
              <feather-icon
                :id="`invoice-row-${item.id}-trash-icon`"
                icon="TrashIcon"
                stroke="red"
                class="mx-1 clickable danger"
                @click="removeItemFromTable(item)"
              />
              <b-tooltip
                :title="$t('delete')"
                placement="bottom"
                :target="`invoice-row-${item.id}-Trashicon`"
              />
            </div>
          </template>
          <template #foot()>
            <span> </span>
          </template>
          <template #foot(debit)>
            <span> {{ fraction(totalDebit) }} </span>
          </template>
          <template #foot(credit)>
            <span> {{ fraction(totalCredit) }} </span>
          </template>
          <template #foot(actions)>
            <span> {{ fraction(totalCredit - totalDebit) }} </span>
          </template>
        </b-table>
      </b-row>
      <div class="pt-5" v-if="showCostcenterOption === true">
        <div class="card-profile pb-3">
          <h3>
            {{ $t("shareToAccount") }} - {{ accountObj.accountArabicName }}
          </h3>
          <h3 v-if="accountObj.credit !== 0">
            {{ $t("share") }} {{ $t("credit") }} {{ $t("withValue") }}
            {{ accountObj.credit }}
          </h3>
          <h3 v-if="accountObj.debit !== 0">
            {{ $t("share") }} {{ $t("debit") }} {{ $t("withValue") }}
            {{ accountObj.debit }}
          </h3>
        </div>
        <b-row>
          <b-col md="4">
            <g-field
              :value.sync="deatilObj.costCenter"
              :dir="isRight ? 'rtl' : 'ltr'"
              label-text="costCenter"
              field="select"
              name="costCenter"
              :options="subCostcenters"
              label="arabicName"
            />
          </b-col>

          <b-col md="3">
            <!-- end  -->
            <g-field
              :value.sync="deatilObj.value"
              :shortDesc="true"
              rules="min_value:0"
              type="number"
              label-text="value"
            />
          </b-col>
          <b-col md="4">
            <g-field
              id="notes"
              :value.sync="deatilObj.notes"
              label-text="notes"
              name="notes"
            />
          </b-col>
          <b-col md="1" class="m-auto">
            <b-button
              data-action-type
              class="mx-1"
              @click="addCostcenter(deatilObj)"
              variant="primary"
              :disabled="
                !deatilObj.costCenter || !deatilObj.value || deatilObj.value < 0
              "
            >
              <feather-icon icon="PlusCircleIcon" />
            </b-button>
          </b-col>
        </b-row>
        <b-row class="p-0">
          <b-col>
            <b-table
              :items="accountObj.costCenters"
              :fields="costcenterTableColumns"
              primary-key="id"
              show-empty
              striped
              hover
              foot-clone
              stickyColumn
              fixed
              class="position-relative"
              :filter="searchQuery"
              :filter-included-fields="filterOn"
              :empty-text="$t('noMatchingRecordsFound')"
              @filtered="onFiltered"
            >
              <!-- Column: Name -->
              <template #cell(arabicName)="data">
                <span>
                  {{ data.item.arabicName }}
                </span>
              </template>
              <template #head(actions)>
                <span />
              </template>
              <template #cell(actions)="data">
                <div class="text-nowrap">
                  <feather-icon
                    :id="`invoice-row-${data.item}-delete-icon`"
                    icon="TrashIcon"
                    stroke="red"
                    class="mx-1 clickable danger"
                    @click="
                      (v) => {
                        removeCostCenter(data.item);
                      }
                    "
                  />
                  <b-tooltip
                    :title="$t('delete')"
                    placement="bottom"
                    :target="`invoice-row-${data.item.id}-delete-icon`"
                  />
                </div>
              </template>
              <template #foot()>
                <span> </span>
              </template>
              <template #foot(value)>
                <span> {{ fraction(totalCostcenters) }} </span>
              </template>
              <template #foot(notes)>
                <span> {{ $t("remining") }} {{ fraction(remindered) }}</span>
              </template>
            </b-table>
          </b-col>
        </b-row>
      </div>
      <b-row class="mt-2">
        <b-col
          cols="12"
          class="d-flex justify-content-end"
        >
          <b-button
            v-if="id"
            class="mr-1"
            variant="outline-primary"
            @click="print(id)"
          >
            <feather-icon icon="PrinterIcon" class="mr-50" />
            {{ $t("print") }}
          </b-button>

          <b-button
            class="mr-1"
            type="submit"
            variant="gradient-primary"
            :disabled="
              selectedItem.isCanceled === true ||
              checkSourcePerm() ||
              isRequested
            "
            data-action-type="save"
            v-permission="
              $route.meta.permission || 'integratedDailyJournalUpdate'
            "
          >
            <feather-icon icon="SaveIcon" class="mr-50" />
            {{ $t("save") }}
          </b-button>

          <b-button
            @click="save('saveAndPrint')"
            v-if="selectedItem.isCanceled !== true || isRequested"
            v-show="!selectedItem.sourceTransactionCode"
            variant="outline-primary"
            data-action-type="saveAndPrint"
            v-permission="$route.meta.permission"
          >
            <feather-icon icon="PrinterIcon" class="mr-50" />
            {{ $t("saveAndPrint") }}
          </b-button>
        </b-col>
      </b-row>
    </g-form>
  </b-card>
</template>

<script>
import saveAs from 'file-saver';
import reportMixin from '@/mixin/reportMixin';
// import { VueAutosuggest } from 'vue-autosuggest'

export default {
  // components: {
  //   VueAutosuggest,
  // },
  props: ['id'],
  mixins: [reportMixin],
  data() {
    return {
      isRequested: false,
      inputProps: {
        id: 'autosuggest__input',
        class: 'form-control',
        placeholder: `${this.$t('search')}`,
      },
      datasuggest: [],
      filteredOptions: [],
      limit: 10,
      selected: null,
      totalRows: 0,
      currentPage: 1,
      selectedItem: {
        voucherType: 'journalVoucher',
        voucherDate: this.today,
        transactionTime: '',
        lines: [],
      },
      isTableBusy: false,
      isDisabled: true,
      filterOn: [],
      searchQuery: '',
      subAccounts: [],
      credit: 0.0,
      debit: 0.0,
      totalDebit: 0.0,
      totalCredit: 0.0,
      subCostcenters: [],
      deatilObj: {
        value: 0.0,
      },
      accountObj: {
        costCenters: [],
      },
      showCostcenterOption: false,
      notAllow: false,
      query: '',
      file: '',
      sourceTransactionType: '',
      checkIsOpening: false
    };
  },
  watch: {
    'selectedItem.lines': {
      handler(val, oldVal) {
        if (oldVal.length || val.length > oldVal.length || val) {
          if (this.selectedItem.lines.length > 0) {
            if (this.selectedItem.lines[this.selectedItem.lines.length - 1].accountId !== null) {
              this.selectedItem.lines.push({
                lineSerial: this.selectedItem.lines.length + 1,
                accountId: null,
                accountCode: '',
                accObj: null,
                accountArabicName: null,
                accountEnglishName: null,
                isDistributable: false,
                credit: 0.0,
                debit: 0.0,
                description: '',
                proofNumber: '',
                costCenters: [],
              })
              this.totalRows = this.selectedItem.lines.length + 1
            }
          }
        }
      },
      deep: true,
    },
  },
  computed: {
    tableColumns() {
      return [
        { key: 'code', label: this.$t('code'), sortable: true },
        {
          key: this.isRight ? 'arabicName' : 'englishName',
          label: this.$t('name'),
          sortable: true,
        },
        {
          key: 'currentBalance',
          label: this.$t('currentBalance'),
          sortable: true,
          formatter: (value, key, item) => {
            item.currentBalance = item.currentDebit
              + item.prevDebit
              - (item.currentCredit + item.prevCredit);
            return item.currentBalance
          },
        },
        { key: 'actions' },
      ];
    },
    secondTableColumns() {
      return [
        {
          key: 'accountCode',
          label: this.$t('code'),
          sortable: false,
          thStyle: { width: '100px' }
        },
        {
          key: this.isRight ? 'accountArabicName' : 'accountEnglishName',
          label: this.$t('name'),
          sortable: false,
          thStyle: { width: '25%' }
        },
        {
          key: 'debit',
          label: this.$t('debit'),
          sortable: false,
          type: 'number',
        },
        {
          key: 'credit',
          label: this.$t('credit'),
          sortable: false,
          type: 'number',
        },
        {
          key: 'proofNumber',
          label: this.$t('proofNumber'),
          sortable: false,
          thStyle: { width: '100px' }
        },
        {
          key: 'description',
          label: this.$t('notes'),
          sortable: false,
          thStyle: { width: '25%' }
        },
        { key: 'actions' },
      ];
    },
    costcenterTableColumns() {
      return [
        { key: 'costCenterCode', label: this.$t('code'), sortable: false },
        {
          key: this.isRight ? 'costCenterArabicName' : 'costCenterEnglishName',
          label: this.$t('name'),
          sortable: false,
        },
        {
          key: 'value',
          label: this.$t('value'),
          sortable: false,
          type: 'number',
          footer: () => this.fraction(this.totalVal),
        },
        { key: 'notes', label: this.$t('notes'), sortable: false },
        { key: 'actions' },
      ];
    },
    totalCostcenters() {
      return this.accountObj.costCenters.reduce((sum, item) => {
        sum += parseFloat(item.value);
        return sum;
      }, 0);
    },
    remindered() {
      if (this.accountObj.debit) {
        return parseFloat(this.accountObj.debit - this.totalCostcenters) || 0
      }
      if (this.accountObj.credit) {
        return parseFloat(this.accountObj.credit - this.totalCostcenters) || 0;
      }
      return this.remindered
    },
  },
  beforeMount() {
    this.fiscalYearStart = this.currentYear.startDate;
    this.fiscalYearEnd = this.currentYear.endDate;
  },
  mounted() {
    this.validateOpening();
    this.selectedItem.transactionTime = this.getTime();
    this.sourceId = this.$route.query.sourceTransactionId;
    this.getAccounts();
    if (this.id > 0 || this.sourceId > 0) {
      this.getData();
      this.getSummary();
    } else {
      this.selectedItem.voucherDate = this.today;
      this.selectedItem.branchId = this.branchId;
    }
  },
  methods: {
    validateYear(date) {
      if (this.getDate(this.fiscalYearStart) > this.getDate(date) || this.getDate(date) > this.getDate(this.fiscalYearEnd)) {
      this.doneAlert({
          type: 'error',
          text: this.$t('youdonothaveaccesstothisyear'),
        });
        return false;
      }
      return true;
    },
    disableValueCredit(item, selectedItem) {
      if (this.profile.permissions.indexOf('integratedDailyJournalUpdate') > -1 || this.profile.isAdmin) {
        return !!Number(item.debit) || item.costCenters.length > 0;
      }
      return !!Number(item.debit) || item.costCenters.length > 0 || selectedItem.sourceTransactionId > 0;
    },
    disableValueDebit(item, selectedItem) {
      if (this.profile.permissions.indexOf('integratedDailyJournalUpdate') > -1 || this.profile.isAdmin) {
        return !!Number(item.credit) || item.costCenters.length > 0;
      }
      return !!Number(item.credit) || item.costCenters.length > 0 || selectedItem.sourceTransactionId > 0;
    },
    checkSourcePerm() {
      if (!this.profile.isAdmin && this.selectedItem.sourceTransactionCode > 0
        && this.profile.permissions.indexOf('integratedDailyJournalUpdate') > -1) {
        return false
      }
      if (this.profile.isAdmin) {
        return false
      }
      return true
    },
    downloadItem(item) {
      const path = item.attachmentUrl.split('.')[1];
      saveAs(`${this.baseUrl}${item.attachmentUrl}`, `attached-file.${path}`);
    },
    removeAttachment() {
      this.selectedItem.attachmentFileExtension = '';
      this.selectedItem.attachmentBase64Content = '';
      this.selectedItem.attachmentFileName = '';
      this.file = '';
      this.closeUploadModal();
    },
    toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    handleFileUpload(event) {
      this.file = event.target.files[0];
      const fileSize = this.file.size / 1024 / 1024;
      this.selectedItem.attachmentFileExtension = this.file.name.split('.').pop();
      this.toBase64(this.file).then((file1) => {
        this.selectedItem.attachmentBase64Content = file1.split(',').pop();
      });
      this.selectedItem.attachmentFileName = URL.createObjectURL(this.file);
      if (!this.isValidType(this.file)) {
        this.file = '';
        this.selectedItem.attachmentFileExtension = '';
        this.selectedItem.attachmentBase64Content = '';
        this.selectedItem.attachmentFileName = '';
        this.doneAlert({ text: this.$t('Only supports upload .pdf, .jpg, .jpeg, .bmp, .png, .doc, .docx, .xlsx, .PNG , .zip suffix files'), type: 'error' });
        this.file = '';
        return false
      }
      if (this.isValidType(this.file) && fileSize > 5) {
        this.file = '';
        this.selectedItem.attachmentFileExtension = '';
        this.selectedItem.attachmentBase64Content = '';
        this.selectedItem.attachmentFileName = '';
        this.doneAlert({ text: this.$t('fileSizeExceedsFiveMiB'), type: 'error' });
        this.file = '';
        return false
      }
    },
    isValidType(file) {
      return /\.(pdf|jpg|jpeg|bmp|png|doc|docx|xlsx|PNG|zip)$/.test(file.name)
    },
    clickHandler() {
    },
    onSelected(option) {
      this.addItemToItemsTable(option.item);
      this.query = '';
    },
    onInputChange(text) {
      if (text === '' || text === undefined) {
        return
      }
      const filteredItemsData = this.subAccounts.filter(item => item.code.toLowerCase().indexOf(text.toLowerCase()) > -1).slice(0, this.limit)
      const filteredItemsDataName = this.subAccounts.filter(item => item.arabicName.toLowerCase().indexOf(text.toLowerCase()) > -1).slice(0, this.limit)
      const filteredData = filteredItemsData.concat(filteredItemsDataName)
      this.filteredOptions = [{
        data: filteredData,
      }]
    },
    addDetailCostCenter(item) {
      this.showCostcenterOption = true;
      this.accountObj = item;
    },
    addCostcenter(item) {
      if (this.accountObj.costCenters) {
        this.totalVal = 0;
        this.accountObj.costCenters.forEach((i) => {
          this.totalVal += Number(i.value);
        });
      }
      if (this.accountObj.debit && this.accountObj.debit < ((this.totalVal || 0) + Number(item.value))) {
        const errorVal = (this.totalVal + Number(item.value)) - this.accountObj.debit;
        this.doneAlert({
          text: `${this.$t('debitLessThanValueEnteredWith')} ${errorVal}`,
          type: 'error',
        });
      } else if (this.accountObj.credit && this.accountObj.credit < ((this.totalVal || 0) + Number(item.value))) {
        const errorVal = Number(item.value) - this.accountObj.credit;
        this.doneAlert({
          text: `${this.$t('creditLessThanValueEnteredWith')} ${errorVal}`,
          type: 'error',
        });
      } else {
        const costCenterItem = this.subCostcenters.find((val) => val.id === item.costCenter);
        this.accountObj.costCenters.push({
          costCenterArabicName: costCenterItem.arabicName,
          costCenterEnglishName: costCenterItem.englishName,
          costCenterId: costCenterItem.id,
          costCenterCode: costCenterItem.code,
          voucherLineSerial: this.accountObj.lineSerial,
          voucherId: this.id,
          lineSerial: Number(this.accountObj.lineSerial),
          ...item,
        });
        this.deatilObj = { value: 0 };
        this.selectedItem.lines[this.itemsIndex] = this.accountObj;
      }
    },
    removeCostCenter(item) {
      // const indx = this.accountObj.costCenters.indexOf(_obj);
      // this.accountObj.costCenters.splice(indx, 1);
      this.items = this.accountObj.costCenters
      const filteredItems = this.items.reduce((items, val) => {
        if (val !== item) {
          const index = items.length > 0 ? items[items.length - 1].lineSerial : 0;
          val.lineSerial = index + 1;
          items.push(val);
        }
        return items;
      }, []);
      this.accountObj.costCenters = filteredItems;
    },
    getSummary() {
      this.totalDebit = 0;
      this.totalCredit = 0;
      this.selectedItem.lines.forEach((item) => {
        this.totalDebit += parseFloat(item.debit) || 0;
        this.totalCredit += parseFloat(item.credit) || 0;
        this.totals = Number(this.fraction(this.totalCredit - this.totalDebit));
      });
    },
    getData() {
      const id = this.id || this.sourceId
      if (id) {
        this.get({
          url: 'Vouchers',
          id: id,
        }).then((data) => {
          this.selectedItem = data;
          this.selectedItem.transactionTime = this.getTime(data.transactionTime);
          if (this.selectedItem.sourceTransactionType) {
            this.sourceTransactionType = this.$t(this.selectedItem.sourceTransactionType)
          }
          if (this.selectedItem.sourceTransactionType) {
            this.sourceTransactionType = this.$t(this.selectedItem.sourceTransactionType)
          }
          this.getSummary();
          this.setCostCenterData();
          if (this.sourceId > 0) this.prepareItemFromSource();
        });
      }
    },

    prepareItemFromSource() {
      this.selectedItem.id = 0;
      this.selectedItem.code = null;
      this.selectedItem.voucherDate = this.today;
      this.selectedItem.uuid = '00000000-0000-0000-0000-000000000000';
      this.selectedItem.updatedBy = null;
      this.selectedItem.updatedAtUtc = null;
      this.selectedItem.lines = this.selectedItem.lines.map((item) => {
         return {
          ...item,
          voucherId: 0,
        };
      });
    },
    setCostCenterData() {
      if (!this.isCurrentTransactionTypeHaveCostCenter()) return;
      this.get({
        url: this.getUrlBasedOnCurrentType(),
         id: this.selectedItem.sourceTransactionId
      }).then((entity) => {
        var account = this.getAccountBasedOnCurrentType(entity) || {};
        account.costCenters = entity.costCenterLines || [];
        account.isDistributable = account.costCenters.length > 0
      })
    },
    isCurrentTransactionTypeHaveCostCenter() {
      return this.selectedItem.sourceTransactionType === 'customerCollection' || this.selectedItem.sourceTransactionType === 'expenseTransaction'
    },
    getAccountBasedOnCurrentType(entity) {
      switch (this.selectedItem.sourceTransactionType) {
        case 'customerCollection':
          return this.selectedItem.lines.find((x) => x.accountId === entity.studentAccountId)

        case 'expenseTransaction':
          return this.selectedItem.lines.find((x) => x.accountId === entity.expenseAccountId)

        default:
          return null;
      }
    },
    getUrlBasedOnCurrentType() {
      switch (this.selectedItem.sourceTransactionType) {
        case 'customerCollection':
          return 'CollectionVouchers'

        case 'expenseTransaction':
          return 'ExpensesTransactions'

        default:
          return '';
      }
    },

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    addItemToItemsTable(item) {
      const _index = this.selectedItem.lines.findIndex(i => i.accountId === null);
      this.selectedItem.lines.splice(_index, 1);

      this.selectedItem.lines.push({
        lineSerial: this.selectedItem.lines.length + 1,
        accountId: item.id,
        accountCode: item.code,
        accountArabicName: item.arabicName,
        accountEnglishName: item.englishName,
        isDistributable: item.isDistributable,
        credit: 0.0,
        debit: 0.0,
        description: '',
        proofNumber: '',
        costCenters: [],
      });

      if (this.selectedItem.lines.length > 25 && this.id) {
        this.totalRows = this.selectedItem.lines.length + 1
      }
    },
    removeItemFromTable(addedItem) {
      if (!addedItem.accountId) return;
      const filteredItems = this.selectedItem.lines.reduce((items, val) => {
        if (val !== addedItem) {
          const index = items.length > 0 ? items[items.length - 1].lineSerial : 0;
          val.lineSerial = index + 1;
          items.push(val);
        }
        return items;
      }, []);
      this.selectedItem.lines = filteredItems;
      // this.selectedItem.lines = this.selectedItem.lines.filter(
      //   (item) => item !== addedItem
      // );
      addedItem.costCenters = [];
      this.showCostcenterOption = false;
      this.getSummary();
    },
    save(type) {
      if (!this.validateYear(this.selectedItem.voucherDate)) return;
      if (this.totals !== 0) {
        this.doneAlert({ text: this.$t('Constraint Unbalanced'), type: 'error' });
      } else if (this.totalCredit === 0 || this.totalDebit === 0) {
        this.doneAlert({ text: this.$t('notAllowedToSaveZeroAmount'), type: 'error' });
      } else {
        this.confirmSave(type);
      }
    },
    beforeSaveValidation() {
      if (this.$can('distributionToCostCenters') && !this.profile.isAdmin) {
        this.selectedItem.lines.forEach((item) => {
          if (item.isDistributable === true && !item.costCenters.length) {
            this.notAllow = true;
          } else {
            this.notAllow = false;
          }
        });
        if (this.notAllow) {
          // alert here
          this.doneAlert({ text: this.$t('You Should Distribution To CostCenters'), type: 'error' });
          return false;
        } return true;
      }
      return true
    },
    confirmSave(type) {
      if (this.selectedItem.lines[this.selectedItem.lines.length - 1].accountId === null) {
        this.selectedItem.lines = this.selectedItem.lines.slice(0, -1)
      }

      this.isRequested = true;
      if (!this.id) {
        this.selectedItem.transactionTime = this.time;
      }
      // if (!this.checkForFiscalYearPermForTrans(this.selectedItem.voucherDate)) return;
      if (!this.beforeSaveValidation()) return;
      if (this.selectedItem.id) {
        this.update({
          url: 'Vouchers',
          data: this.selectedItem,
          id: this.selectedItem.id,
        })
          .then(() => {
            this.doneAlert({ text: this.$t('updatedSuccessfully') });
            if (type === 'saveAndPrint') this.print(this.selectedItem.id);
            this.$router.push({ name: 'vouchers' });
          }).finally(() => {
            this.isRequested = false
          })
      } else {
        this.selectedItem.transactionTime = this.time;
        this.create({
          url: 'Vouchers',
          data: this.selectedItem,
        })
          .then((dataId) => {
            this.doneAlert({ text: this.$t('savedSuccessfully') });
            if (type === 'saveAndPrint') this.print(dataId);
            this.$router.push({ name: 'vouchers' });
          }).finally(() => {
            this.isRequested = false
          })
      }
    },
    getAccounts() {
      this.get({ url: 'aggregates/accounts' }).then((data) => {
        data.accounts.forEach(item => {
          item.name = `${item.code} - ${item.arabicName}`
        });
        this.subAccounts = data.accounts;
        this.subCostcenters = data.costCenters;
        this.subAccounts.sort((a, b) => a.code - b.code);
      });
    },
    openModal() {
      this.$refs['search-modal'].show();
    },
    closeModal() {
      this.searchQuery = ''
      this.$refs['search-modal'].hide();
    },
    closeUploadModal() {
      this.$refs['upload-modal'].hide();
    },
    openUploadModal() {
      this.$refs['upload-modal'].show();
    },
    print(id) {
      const printedItem = {
        id: id
      }
      this.printReport(this.isRight ? 'VouchersReport-ar' : 'VouchersReport-en', printedItem);
    },
    validateOpening() {
      this.get({ url: 'Vouchers/opening-voucher' }).then((data) => {
        this.checkIsOpening = data;
      })
    },
  },
};
</script>

<style lang="css">
#autosuggest {
  width: 75% !important;
}
.autosuggest__results-container .autosuggest__results {
  background-color: #fff;
  margin-top: 1rem;
  border-radius: 0.5rem;
  -webkit-box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.11),
    0 5px 15px 0 rgba(0, 0, 0, 0.08) !important;
  box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.11),
    0 5px 15px 0 rgba(0, 0, 0, 0.08) !important;
  position: absolute;
  width: 100%;
  overflow-y: auto;
  max-height: 40vh;
  z-index: 999;
}
.autosuggest__results-container .autosuggest__results ul li:hover {
  background: #ededed;
  cursor: pointer;
}
.autosuggest__results-container .autosuggest__results ul li {
  list-style: none;
  padding: 0.75rem 1rem;
  cursor: pointer;
}
.autosuggest__results-container .autosuggest__results ul {
  padding-left: 0;
  margin-bottom: 0;
}

@media (max-width: 768px) {
  .add-account {
    margin-top: 15px;
  }
}
</style>
